'use client';

import { createContext } from 'react';

export type CookiesConsentContext = {
  isReady: boolean;
  showConsentManager: () => void;
  getConsents: () => Record<string, boolean>;
  hasConsent: (vendor: string) => boolean;
};
const defaultContextProps: CookiesConsentContext = {
  isReady: false,
  showConsentManager: () => undefined,
  getConsents: () => ({}),
  hasConsent: () => false,
};
export const CookiesConsentContext = createContext<CookiesConsentContext>(defaultContextProps);

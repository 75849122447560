'use client';

import { Configuration } from '../configuration';

type CookiesConsents = Record<string, boolean>;
export class AxeptioService {
  constructor(
    private readonly locale: string,
    private readonly configuration: Configuration,
    webhooks: {
      onConsentChange: (consents: CookiesConsents) => void;
      onSdkReady: () => void;
    },
  ) {
    if (!configuration.isEnabled || typeof window === 'undefined') return;

    (window as any).axeptioSettings = {
      ...(window as any).axeptioSettings,
      clientId: this.configuration.clientId,
      userCookiesDomain: this.configuration.userCookiesDomain,
      cookiesVersion: this.configuration.supportedLocales.includes(this.locale)
        ? this.locale
        : this.configuration.defaultLocale,
    };

    (window as any)._axcb = ((window as any)._axcb ?? []).concat((axeptio: any): void => {
      axeptio.on('cookies:complete', (consents: CookiesConsents): void =>
        webhooks.onConsentChange(AxeptioService._parseCookies(consents)),
      );
      axeptio.on('ready', () => webhooks.onSdkReady());
      axeptio.on('close', () => this.hideCookiesWidget());
    });
  }

  private static _parseCookies(consents: CookiesConsents): CookiesConsents {
    const parsedConsents: CookiesConsents = {};

    for (const [key, value] of Object.entries(consents)) {
      if (key.startsWith('$$')) continue;

      parsedConsents[key] = value;
    }

    return parsedConsents;
  }

  public showCookiesWidget(): void {
    if (!this.configuration.isEnabled || typeof window === 'undefined') return;
    (window as any)?.openAxeptioCookies?.();
  }

  public hideCookiesWidget(): void {
    if (!this.configuration.isEnabled || typeof window === 'undefined') return;
    (window as any)?.hideAxeptioButton?.();
  }

  get isReady(): boolean {
    if (!this.configuration.isEnabled || typeof window === 'undefined') return false;
    return (window as any).axeptioSDK?.isReady || false;
  }
}

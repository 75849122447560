/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import cookies from 'js-cookie';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useCookiesConsent } from '../../cookies';
import { useClientSideEnv } from '../../client-side-configuration';

const ANALYTICS_COOKIE_NAME = 'analytics';
export const AnalyticsProvider = (props: PropsWithChildren<{ userId?: string; host: string }>): JSX.Element => {
  const [isPosthogInit, setIsPosthogInit] = useState<boolean>(false);
  const [consentForAnalytics, setConsentForAnalytics] = useState<'uncertain' | 'denied' | 'granted'>('uncertain');
  const cookiesConsent = useCookiesConsent();
  const [userId, setUserId] = useState<string | undefined>(props.userId);
  const { analytics: configuration } = useClientSideEnv();

  /**
   * This effect is responsible for updating the consent status for analytics
   * and ensure no other useEffect is triggered until consent specific to analytics
   * changes.
   */
  useEffect(() => {
    if (!configuration.isEnabled) return;

    if (!cookiesConsent.isReady) return;

    const hasConsent = cookiesConsent.hasConsent(ANALYTICS_COOKIE_NAME);

    // Scenario: consent was uncertain as the SDK was not ready
    if (consentForAnalytics === 'uncertain') {
      setConsentForAnalytics(hasConsent ? 'granted' : 'denied');
      return;
    }

    // Scenario: consent was denied and is granted now
    if (hasConsent && consentForAnalytics === 'denied') {
      setConsentForAnalytics('granted');
      return;
    }

    // Scenario: consent was granted and is denied now
    if (!hasConsent && consentForAnalytics === 'granted') {
      setConsentForAnalytics('denied');
      return;
    }

    // No change detected
  }, [cookiesConsent]);

  /**
   * This effect is responsible for initializing posthog and toggling
   * event capturing based on the consent status.
   *
   * * NB: posthog persistence mode is set on 'memory' if no consent is given
   * * to ensure no cookie is set on the user's browser.
   */
  useEffect(() => {
    if (!configuration.isEnabled) return;

    if (consentForAnalytics === 'uncertain') return;

    if (isPosthogInit && consentForAnalytics === 'granted') {
      posthog.opt_in_capturing();
      posthog._start_queue_if_opted_in();
      return;
    }

    if (isPosthogInit && consentForAnalytics === 'denied') {
      posthog.opt_out_capturing();
      cookies.remove(`ph_${configuration.key}_posthog`);
      return;
    }

    posthog.init(configuration.key, {
      api_host: `${props.host}/ingest`,
      ui_host: configuration.url,
      opt_out_capturing_by_default: consentForAnalytics === 'denied',
      persistence: consentForAnalytics === 'granted' ? 'localStorage+cookie' : 'memory',
      bootstrap: {
        distinctID: props.userId,
        isIdentifiedID: typeof props.userId === 'string',
      },
    });
    setIsPosthogInit(true);
  }, [consentForAnalytics, isPosthogInit]);

  /**
   * This effect is responsible for identifying the user if a userId is provided
   * and resetting the analytics properties if the userId is removed/logged out.
   *
   * * NB: Only userId is used to identify the user, other properties should not
   * * be sent to Posthog.
   */
  useEffect(() => {
    if (!isPosthogInit) return;
    if (props.userId === userId) return;

    if (props.userId) posthog.identify(props.userId);
    else posthog.reset();

    setUserId(props.userId);
  }, [props.userId, isPosthogInit]);

  return <PostHogProvider client={posthog}>{props.children}</PostHogProvider>;
};
